import React from "react";
import { Link as RouterLink } from "react-router-dom";

interface LinkProps {
  to: string;
  children: React.ReactNode;
}

const Link: React.FC<LinkProps> = ({ to, children }) => {
  return (
    <RouterLink
      to={to}
      className="text-blue-500 hover:text-blue-700 transition duration-200"
    >
      {children}
    </RouterLink>
  );
};

export default Link;
