import Home from "../pages/HomePage";
import About from "../pages/AboutPage";
import Contact from "../pages/ContactPage";
import Events from "../pages/EventsPage";

export const directory = [
  { name: "Home", path: "/", page: Home },
  { name: "About", path: "/about", page: About },
  { name: "Contact", path: "/contact", page: Contact },
  { name: "Find Events", path: "/events", page: Events },
];
