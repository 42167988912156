import React from "react";
import { NavLink } from "react-router-dom";
import { directory } from "../util/directory";
import Copyright from "../components/Copyright";
import logo512 from "../assets/images/logo512.png";

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen bg-slate-100">
      <header className="p-5 border-b border-slate-300">
        <div className="container mx-auto flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <div className="flex items-center space-x-4">
            <img className="h-10" src={logo512} alt="Hedron Network" />
            <h1 className="text-black text-3xl font-medium">Hedron Network</h1>
          </div>
          <div className="flex flex-wrap justify-around items-center gap-5">
            {directory.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.path}
                className={({ isActive }) =>
                  isActive
                    ? "text-blue-500 border-b-2 border-blue-500 text-lg font-medium"
                    : "text-black text-lg hover:text-blue-500 transition duration-300 font-medium"
                }
              >
                {tab.name}
              </NavLink>
            ))}
          </div>
        </div>
      </header>
      <main className="flex-grow bg-slate-200 flex flex-col items-stretch">
        {children}
      </main>
      <footer className="p-5 bg-slate-800 text-white text-sm">
        <div className="mx-auto text-center">
          <Copyright />
        </div>
      </footer>
    </div>
  );
};

export default MainLayout;
