import React, { ReactNode, HTMLAttributes } from "react";

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
}

const Card: React.FC<CardProps> = ({ children, className = "", ...props }) => {
  return (
    <div
      className={`bg-slate-100 shadow-sm rounded-md w-full border border-slate-300 ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

export default Card;
