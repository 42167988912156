import React from "react";

import logo from "../assets/images/logo.png";
import screen1 from "../assets/images/screen1.png";
import screen2 from "../assets/images/screen2.png";
import screen3 from "../assets/images/screen3.png";

import MainLayout from "../layouts/MainLayout";
import Link from "../components/base/Link";

const HomePage: React.FC = () => {
  return (
    <MainLayout>
      <div className="flex flex-col items-stretch justify-center gap-5 flex-grow">
        <div className="flex flex-col items-center justify-center">
          <img
            className="h-64 animate-spin-slow my-10"
            src={logo}
            alt="Hedron Network"
          />
          <h1 className="text-2xl md:text-6xl font-medium mb-5">
            Hedron Network
          </h1>
          <p className="text-md md:text-2xl">
            Tournament Software for Magic: The Gathering Cube
          </p>
        </div>
        <div className="flex flex-col items-stretch justify-center bg-slate-100 border-y border-slate-300 p-2 text-center">
          <div className="container mx-auto">
            <div className="flex justify-center items-center gap-10">
              <h1 className="w-1/3 text-md md:text-2xl font-medium mb-5 text-right">
                Solve Logistical Challenges
              </h1>
              <p className="w-2/3 text-sm md:text-lg mb-3 text-left">
                Hedron Network is designed to address the logistical challenges
                of large-scale Cube and draft events including assigning players
                to Cubes as well as handling matchmaking, reporting, and
                standings.
              </p>
            </div>
            <div className="flex justify-center items-center gap-10">
              <h1 className="w-1/3 text-md md:text-2xl font-medium mb-5 text-right">
                Optimize Player Experience
              </h1>
              <p className="w-2/3 text-sm md:text-lg mb-3 text-left">
                Our solution leverages a unique algorithm that matches players
                with Cubes based on their ranked choice votes. This ensures high
                player satisfaction by providing a personalized gaming
                experience.
              </p>
            </div>
            <div className="flex justify-center items-center gap-10">
              <h1 className="w-1/3 text-md md:text-2xl font-medium mb-5 text-right">
                Judge Effectively
              </h1>
              <p className="w-2/3 text-sm md:text-lg mb-3 text-left">
                We offer an easy-to-use interface for judges, allowing them to
                manage each draft on a by-draft cadence and resolve any issues
                without the need for escalation.
              </p>
            </div>
            <div className="flex justify-center items-center gap-10">
              <h1 className="w-1/3 text-md md:text-2xl font-medium mb-5 text-right">
                Add Security
              </h1>
              <p className="w-2/3 text-sm md:text-lg mb-3 text-left">
                To protect Cube owners, Hedron Network provides image storage
                for all drafts, creating an audit trail for all cards involved.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center  border-slate-300 text-center px-2">
          <div className="flex flex-wrap justify-around items-center gap-2">
            <img
              className="max-w-full h-auto my-2"
              src={screen1}
              alt="Screenshot 1"
            />
            <img
              className="max-w-full h-auto my-2"
              src={screen2}
              alt="Screenshot 2"
            />
            <img
              className="max-w-full h-auto my-2"
              src={screen3}
              alt="Screenshot 3"
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center bg-slate-100 border-y border-slate-300 p-10 text-center">
          <p className="text-lg mb-3">
            Hedron Network is currently available for to lease for your next
            event.
          </p>
          <p className="text-lg mb-3">
            We are flexible and can work with you to meet your needs.
          </p>
          <p className="text-lg mb-3">
            <Link to="/contact">Contact us</Link> today to learn more.
          </p>
        </div>
      </div>
    </MainLayout>
  );
};

export default HomePage;
