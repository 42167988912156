import React from "react";

import MainLayout from "../layouts/MainLayout";
import Card from "../components/base/Card";
import CardBody from "../components/base/CardBody";
import Link from "../components/base/Link";

const AboutPage: React.FC = () => {
  return (
    <MainLayout>
      <div className="container mx-auto flex flex-col items-center px-2">
        <Card className="my-3 w-full md:w-1/2">
          <CardBody>
            <h1 className="text-lg md:text-2xl font-medium mb-5">
              About Hedron Network
            </h1>
            <p className="text-md md:text-lg">
              Hedron Network was first conceived in 2022 as a solution to the
              logistical challenges of large-scale Cube events. It was built to
              be used by <Link to="https://www.cubecon.org/">Cube Con</Link> in
              2022, and was further polished for use in 2023 by the same event.
              Since then, it's been used for many Cube events and has solidified
              itself as the premier Cube event software.
            </p>
          </CardBody>
          <CardBody>
            <h1 className="text-lg md:text-2xl font-medium mb-5">About Me</h1>
            <p className="text-md md:text-lg">
              I'm <Link to="gdekker.io">Gwen Dekker</Link>, a software engineer
              passionate about MTG: Cube. You may have heard of me from my work
              on <Link to="https://www.cubecobra.org/">Cube Cobra</Link>.
            </p>
            <p className="text-md md:text-lg">
              Hedron Network is solely my work, and I'm proud to have built it
              from the ground up. My main goal is to grow Cube as a format, and
              I hope that Hedron Network can help enable more and better Cube
              events all around the world.
            </p>
          </CardBody>
          <CardBody>
            <h1 className="text-lg md:text-2xl font-medium mb-5">Leasing</h1>
            <p className="text-md md:text-lg">
              Hedron Network is available for lease for your event. The cost
              depends on a case by case basis, depending on the size and
              duration of your event.
            </p>
            <p className="text-md md:text-lg">
              Generally, leasing agreements include the following provisions:
              <ul className="list-disc list-inside">
                <li>
                  A custom domain for your event, live for two weeks leading up
                  to the event
                </li>
                <li>A training session for event staff</li>
                <li>On-call support during the event</li>
              </ul>
            </p>
          </CardBody>
        </Card>
      </div>
    </MainLayout>
  );
};

export default AboutPage;
