import React from "react";

import MainLayout from "../layouts/MainLayout";
import Card from "../components/base/Card";
import CardBody from "../components/base/CardBody";
import Link from "../components/base/Link";

const Contact: React.FC = () => {
  return (
    <MainLayout>
      <div className="container mx-auto flex flex-col items-center px-2">
        <Card className="my-3 w-full md:w-1/2">
          <CardBody>
            <h1 className="text-lg md:text-2xl font-medium mb-5">Contact Us</h1>
            <p className="text-md md:text-lg">
              If you're interested in leasing Hedron Network for your event, or
              if you have any questions, please reach out to us at{" "}
              <Link to="mailto:admin@hedron.network">admin@hedron.network</Link>
              .
            </p>
            <p className="text-md md:text-lg">
              Feel free to alternatively reach out to me personally via Discord
              (DEKKARU#2784) or on Twitter (
              <Link to="https://twitter.com/GwenDekker">@GwenDekker</Link>).
            </p>
          </CardBody>
        </Card>
      </div>
    </MainLayout>
  );
};

export default Contact;
