import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

interface ButtonLinkProps {
  to: string;
  children: React.ReactNode;
  color?: string;
}

const ButtonLink: React.FC<ButtonLinkProps> = ({ to, children, color }) => {
  return (
    <Link
      to={to}
      className={classNames({
        "px-6 py-1 bg-blue-500 text-white rounded d text-md font-medium hover:bg-blue-700 transition duration-200 inline-block":
          true,
        "border-red-500 bg-red-500 text-neutral-100 hover:bg-red-700 hover:text-neutral-100":
          color === "danger",
      })}
    >
      {children}
    </Link>
  );
};

export default ButtonLink;
