import React from "react";

import MainLayout from "../layouts/MainLayout";
import Card from "../components/base/Card";
import CardBody from "../components/base/CardBody";
import ButtonLink from "../components/base/ButtonLink";

import bostoncubeparty from "../assets/images/bostoncubeparty.jpg";
import calicubechamps from "../assets/images/californiacubechamps.png";
import scc from "../assets/images/scc.jpg";
import cubecon from "../assets/images/cubecon.png";
import upkeep from "../assets/images/upkeep.jpg";
import capitolcube from "../assets/images/capitolcube.jpg";

const events = [
  {
    name: "Boston Cube Party",
    date: "April 12-13, 2025",
    location: "Cambridge, MA",
    url: "https://bostoncubeparty.com",
    about:
      "Join us in Boston for the second annual Boston Cube Party! After seeing all of the wonderful cube events pop up across the country after the success of CubeCon, we wanted to show off our vibrant cube community in Boston. We're so excited to share our cubes and our community with all of you!",
    image: bostoncubeparty,
    hedronUrl: null,
  },
];

const AboutPage: React.FC = () => {
  return (
    <MainLayout>
      <div className="container mx-auto flex flex-col items-center px-2">
        {events.length > 0 ? (
          events.map(
            ({ name, date, location, url, about, image, hedronUrl }) => (
              <Card className="my-2">
                <CardBody>
                  <div className="flex justify-content-start gap-4">
                    <div className="flex items-center">
                      <img
                        src={image}
                        alt={name}
                        className="w-80 object-cover rounded-md"
                      />
                    </div>
                    <div className="flex flex-col justify-between gap-4 w-full">
                      <div className="flex flex-col justify-start gap-2">
                        <h2 className="text-lg font-medium">{name}</h2>
                        <p>
                          {date} - {location}
                        </p>
                        <p>{about}</p>
                      </div>
                      <div className="flex gap-4">
                        <ButtonLink to={url}>Event Site</ButtonLink>
                        {hedronUrl && (
                          <ButtonLink to={hedronUrl}>
                            Go to {name} Hedron Site
                          </ButtonLink>
                        )}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            )
          )
        ) : (
          <Card className="mt-2">
            <CardBody className="flex flex-col justify-content-start gap-2">
              <p>
                Sorry, there do not appear to be any events currently scheduled.
                Please check back later.
              </p>
            </CardBody>
          </Card>
        )}
      </div>
    </MainLayout>
  );
};

export default AboutPage;
